import React from "react";
import { Link } from "react-router-dom";
import img1 from "../../assets/img/news/presidentcup.jpg";
import img2 from "../../assets/img/news/inaugoration1.jpg";
import img3 from "../../assets/img/news/nursingweek.jpg";

function NewsHealine() {
  return (
    <>
      <section
        id="blog"
        className="blog-area p-relative fix pt-120 pb-90"
        style={{
          backgroundImage: "url(assets/img/bg/blog_bg.png)",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top",
        }}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div
                className="section-title center-align mb-50 text-center wow fadeInDown animated"
                data-animation="fadeInDown"
                data-delay=".4s"
              >
                <h5>
                  <i className="fal fa-graduation-cap" />
                  LANDMARK News
                </h5>
                <h2>Campus News</h2>
              </div>
            </div>
          </div>
          <div className="row">
            {/* <div className="col-lg-4 col-md-6">
            <div
              className="single-post2 hover-zoomin mb-30 wow fadeInUp animated"
              data-animation="fadeInUp"
              data-delay=".4s"
            >
              <div className="blog-thumb2">
                <a
                  href="https://www.facebook.com/lmucameroonofficial/posts/pfbid0Rz6i7qTWkznSqA3XHkHJawViDw3NZnQpeAvubnywPKUp4aP1B2uD3B6oBJjeFu4Pl"
                  target="_blank"
                >
                  <img src={img1} alt="img" />
                </a>
                <div className="date-home">
                  <i className="fal fa-calendar-alt" /> July 14, 2023
                </div>
              </div>
              <div className="blog-content2">
                {/* <div className="b-meta">
                  <div className="meta-info">
                    <ul>
                      <li>
                        <i className="fal fa-user" /> By Admin{" "}
                      </li>
                      <li>
                        <i className="fal fa-comments" /> 3 Comments
                      </li>
                    </ul>
                </div>
                </div> 
                <h4>
                  <a
                    href="https://www.facebook.com/lmucameroonofficial/posts/pfbid0Rz6i7qTWkznSqA3XHkHJawViDw3NZnQpeAvubnywPKUp4aP1B2uD3B6oBJjeFu4Pl"
                    target="_blank"
                  >
                    LANDMARK 75 days DevOps Skill aquisation program
                  </a>
                </h4>
                  <p>
                  Curabitur sagittis libero tincidunt tempor finibus. Mauris
                  at dignissim ligula, nec tristique orci.
                </p>
                <div className="blog-btn">
                  <a
                    href="https://www.facebook.com/lmucameroonofficial/posts/pfbid0Rz6i7qTWkznSqA3XHkHJawViDw3NZnQpeAvubnywPKUp4aP1B2uD3B6oBJjeFu4Pl"
                    target="_blank"
                  >
                    Read More <i className="fal fa-long-arrow-right" />
                  </a>
                </div>
              </div>
            </div>
          </div>  */}
            <div className="col-lg-4 col-md-6">
              <div
                className="single-post2 mb-30 hover-zoomin wow fadeInUp animated"
                data-animation="fadeInUp"
                data-delay=".4s"
              >
                <div className="blog-thumb2">
                  <a
                    href="https://www.facebook.com/lmucameroonofficial"
                    target="_blank"
                  >
                    <img src={img1} alt="img" />
                  </a>
                  <div className="date-home">
                    <i className="fal fa-calendar-alt" /> May 15, 2024
                  </div>
                </div>
                <div className="blog-content2">
                  <div className="b-meta">
                    <div className="meta-info">
                      {/*<ul>
                        <li>
                          <i className="fal fa-user" /> By Admin{" "}
                        </li>
                        <li>
                          <i className="fal fa-comments" /> 3 Comments
                        </li>
                      </ul>*/}
                    </div>
                  </div>
                  <h4>
                    <a
                      href="https://www.facebook.com/lmucameroonofficial"
                      target="_blank"
                    >
                      LANDMARK PRESIDENT'S FOOTBALL TOURNAMENT 2024. We are
                      thrilled 🕺💃🏿to announce to the general public the
                      launching of the President's Football ⚽ Tournament🏆of
                      Landmark Metropolitan University Buea, which officially
                      took off today Wednesday the 15th of May 2024.
                    </a>
                  </h4>
                  {/* <p>
                    Curabitur sagittis libero tincidunt tempor finibus. Mauris
                    at dignissim ligula, nec tristique orci.
                  </p> */}
                  <div className="blog-btn">
                    <a
                      href="https://www.facebook.com/lmucameroonofficial"
                      target="_blank"
                    >
                      Read More <i className="fal fa-long-arrow-right" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div
                className="single-post2 mb-30 hover-zoomin wow fadeInUp animated"
                data-animation="fadeInUp"
                data-delay=".4s"
              >
                <div className="blog-thumb2">
                  <a
                    href="https://www.facebook.com/lmucameroonofficial"
                    target="_blank"
                  >
                    <img src={img3} alt="img2" />
                  </a>
                  <div className="date-home">
                    <i className="fal fa-calendar-alt" /> May 12, 2024
                  </div>
                </div>
                <div className="blog-content2">
                  <div className="b-meta">
                    <div className="meta-info">
                      {/*<ul>
                        <li>
                          <i className="fal fa-user" /> By Admin{" "}
                        </li>
                        <li>
                          <i className="fal fa-comments" /> 3 Comments
                        </li>
                      </ul>*/}
                    </div>
                  </div>
                  <h4>
                    <a
                      href="https://www.facebook.com/lmucameroonofficial"
                      target="_blank"
                    >
                      *INTERNATIONAL* *NURSES *DAY* *2024* . Happy international
                      nurses day to all landmark metropolitan university
                      students nurses. indeed "nurses makes the difference ".
                    </a>
                  </h4>
                  {/* <p>
                    Curabitur sagittis libero tincidunt tempor finibus. Mauris
                    at dignissim ligula, nec tristique orci.
                  </p> */}
                  <div className="blog-btn">
                    <a
                      href="https://www.facebook.com/lmucameroonofficial"
                      target="_blank"
                    >
                      Read More <i className="fal fa-long-arrow-right" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div
                className="single-post2 mb-30 hover-zoomin wow fadeInUp animated"
                data-animation="fadeInUp"
                data-delay=".4s"
              >
                <div className="blog-thumb2">
                  <a
                    href="https://www.facebook.com/lmucameroonofficial"
                    target="_blank"
                  >
                    <img src={img2} alt="img" />
                  </a>
                  <div className="date-home">
                    <i className="fal fa-calendar-alt" /> October 10, 2023
                  </div>
                </div>
                <div className="blog-content2">
                  <div className="b-meta">
                    <div className="meta-info">
                      {/*<ul>
                        <li>
                          <i className="fal fa-user" /> By Admin{" "}
                        </li>
                        <li>
                          <i className="fal fa-comments" /> 3 Comments
                        </li>
                      </ul>*/}
                    </div>
                  </div>
                  <h4>
                    <a
                      href="https://www.facebook.com/lmucameroonofficial"
                      target="_blank"
                    >
                      THE PRESIDENT OF LANDMARK METROPOLITAN UNIVERSITY INSTALLS
                      TOP MANAGEMENT STAFF OF HIS INSTITUTION
                    </a>
                  </h4>
                  {/* <p>
                    Curabitur sagittis libero tincidunt tempor finibus. Mauris
                    at dignissim ligula, nec tristique orci.
                  </p> */}
                  <div className="blog-btn">
                    <a
                      href="https://www.facebook.com/lmucameroonofficial"
                      target="_blank"
                    >
                      Read More <i className="fal fa-long-arrow-right" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="slider-btn mt-20"
            style={{
              display: "block",
              textAlign: "center",
              padding: "0px",
              margin: "0px",
            }}
          >
            <a
              href="https://www.facebook.com/lmucameroonofficial"
              target="_blank"
              className="btn ss-btn smoth-scroll"
            >
              More News <i className="fal fa-long-arrow-right" />
            </a>
          </div>
        </div>
      </section>
    </>
  );
}

export default NewsHealine;
